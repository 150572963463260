.home {
  min-height: 70vh;
  &_divider {
    margin: 20px 0;
  }
}

.create {
  &__options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // gap: 10px;

    // @media only screen and (min-width: 780px) and (max-width: 900px) {
    //   flex-direction: row;
    //   justify-content: space-between;
    //   align-items: center;
    // }
    // @media only screen and (min-width: 900px) {
    //   display: flex;
    //   flex-direction: column;
    //   gap: 10px;
    // }
    // @media only screen and (min-width: 1170px) {
    //   flex-direction: row;
    //   justify-content: space-between;
    //   align-items: center;
    // }
  }

  .marks_row {
    margin: 30px 0 10px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: start;
    align-items: center;
    gap: 20px;
    @media only screen and (min-width: 600px) {
      display: flex;
      flex-direction: row;
      gap: 30px;
      justify-content: center;
    }
    .marks_container {
      display: flex;
      gap: 10px;

      .marks_title {
        font-weight: 700;
        font-size: 16px;
        color: var(--secondary-color);
      }
      .marks_value {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }

  .auth_select_container {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .auth_select {
    width: 100px;

    @media only screen and (min-width: 600px) {
      width: 150px;
    }
  }

  .placeholder_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 100px 0;
    .icon {
      font-size: 80px;
      width: 50px;
      height: 50px;
    }
    .heading {
      font-size: 30px;
      margin-top: 20px;
      font-weight: 700;
      color: var(--secondary-color);
    }
  }

  &__questions {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin: 50px 0;
    justify-content: center;
    gap: 15px;
    @media only screen and (min-width: 800px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      justify-content: start;
    }
    @media only screen and (min-width: 1600px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      justify-content: start;
    }

    &_container {
      // width: 800px;
      background: #ffffff;
      border: 1px solid #c2c2c2;
      border-radius: 5px;
      padding: 20px 30px;
      // width: 400px;
      // min-height: 200px;
    }

    &_question {
      font-size: 15px;
      line-height: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
      // font-weight: 500;
    }
    &_answer {
      font-size: 15px;
      line-height: 20px;
      margin-top: 5px;
      width: 100%;
      // font-weight: 500;
    }
  }
}

.create_question {
  // padding: 50px 0;
  &_select {
    width: 100px;
    // height: 100px;
    @media only screen and (min-width: 600px) {
      width: 100%;
    }
  }

  &_label {
    margin-top: 20px;
    margin-bottom: 5px;
  }

  &_question {
    font-size: 15px;
    line-height: 20px;
    margin-top: 10px;
    font-weight: 500;
  }
  &_answer {
    font-size: 15px;
    line-height: 20px;
    margin-top: 5px;
    font-weight: 500;
  }
  // .type_row{

  // }
}

.created {
  &__options {
    display: flex;
    flex-direction: column;
    gap: 10px;

    // @media only screen and (min-width: 850px) and (max-width: 900px) {
    //   flex-direction: row;
    //   justify-content: space-between;
    //   align-items: center;
    // }
    // @media only screen and (min-width: 900px) {
    //   display: flex;
    //   flex-direction: column;
    //   gap: 10px;
    // }
    @media only screen and (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .auth_select_container {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .auth_select {
    width: 100px;

    @media only screen and (min-width: 600px) {
      width: 150px;
    }
  }

  .placeholder_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 100px 0;
    .icon {
      font-size: 80px;
      width: 50px;
      height: 50px;
    }
    .heading {
      font-size: 30px;
      margin-top: 20px;
      font-weight: 700;
      color: var(--secondary-color);
    }
  }

  &__questions {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin: 50px 0;
    justify-content: center;
    gap: 15px;
    @media only screen and (min-width: 800px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      justify-content: start;
    }
    @media only screen and (min-width: 1600px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      justify-content: start;
    }

    &_container {
      // width: 800px;
      background: #ffffff;
      border: 1px solid #c2c2c2;
      border-radius: 5px;
      padding: 20px 30px;
      // width: 400px;
      // min-height: 200px;
    }

    &_question {
      font-size: 15px;
      line-height: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
      // font-weight: 500;
    }
    &_answer {
      font-size: 15px;
      line-height: 20px;
      margin-top: 5px;
      // font-weight: 500;
    }
  }
}

.submission {
  &__options {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media only screen and (min-width: 780px) and (max-width: 900px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    @media only screen and (min-width: 900px) {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    @media only screen and (min-width: 1170px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .auth_select_container {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .auth_select {
    width: 100px;

    @media only screen and (min-width: 600px) {
      width: 150px;
    }
  }

  .placeholder_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 100px 0;
    .icon {
      font-size: 80px;
      width: 50px;
      height: 50px;
    }
    .heading {
      font-size: 30px;
      margin-top: 20px;
      font-weight: 700;
      color: var(--secondary-color);
    }
  }
}

.results {
  &__options {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media only screen and (min-width: 780px) and (max-width: 900px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    @media only screen and (min-width: 900px) {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    @media only screen and (min-width: 1170px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .auth_select_container {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .auth_select {
    width: 100px;

    @media only screen and (min-width: 600px) {
      width: 150px;
    }
  }

  .placeholder_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 100px 0;
    .icon {
      font-size: 80px;
      width: 50px;
      height: 50px;
    }
    .heading {
      font-size: 30px;
      margin-top: 20px;
      font-weight: 700;
      color: var(--secondary-color);
    }
  }
}

.compile_row {
  // width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin: 20px;
}
.compile_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.compile_box {
  // padding: 20px 15px;
  border: 2px solid var(--secondary-color);
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  color: var(--secondary-color);
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.compile_tag {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-color);
}

.compile_score {
  font-size: 18px;
  font-weight: 600;
}

.compile_student {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 30px 0;
  &_name {
    font-size: 18px;
    font-weight: 600;
    color: var(--secondary-color);
    text-align: center;
  }
}

.spinner_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px 0;
  gap: 10px;
  p {
    font-size: 18px;
    color: var(--primary-color);
  }
}
