.doc-icon {
  font-size: 80px;
  width: 50px;
  height: 50px;
}

.create-text-heading {
  font-size: 30px;
  margin-top: 20px;
  font-weight: 700;
  color: var(--secondary-color);
}

.create-text-subheading {
  font-size: 18px;
  margin-top: 20px;
  //   font-weight: 700;
  //   color: rgb(15, 165, 165);
}

.assignment_container {
  min-height: 50vh;
}

.assignmentCreate {
  &_row1 {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.row-1-select {
  width: 100px;

  @media only screen and (min-width: 600px) {
    width: 150px;
  }
}

.assignment-preview-container {
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  padding: 20px 30px;
  min-width: 400px;
  min-height: 200px;
}
